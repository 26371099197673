import { Button } from 'components/Button';
import { AutocompleteInput, Input } from 'components/Form';
import { CheckboxInput } from 'components/Form/Input/CheckboxInput';
import { useCallback, useState } from 'react';

import { useFieldArray, useForm } from 'react-hook-form';
import { FaArrowDown, FaArrowUp, FaDollarSign, FaPlus } from 'react-icons/fa6';
import { HiOutlineInformationCircle, HiTrash } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';

import styles from 'styles/Settings.module.css';

const CUSTOM_RATES_VEHICLES = [
    { vehicle_class: 'Tractor Trailer', display_name: 'Van' },
    { vehicle_class: 'Refrigerated', display_name: 'Refrigerated' },
    { vehicle_class: 'Flatbed', display_name: 'Flatbed' },
];

const CUSTOM_RATES_DISTANCES = [
    { id: 0, min: undefined, max: 200, label: '<200 miles' },
    { id: 1, min: 201, max: 500, label: '201-500 miles' },
    { id: 2, min: 501, max: 1000, label: '501-1000 miles' },
    { id: 3, min: 1001, max: 1500, label: '1001-1500 miles' },
    { id: 4, min: 1501, max: undefined, label: '1500+ miles' },
];

const RateProviderItem = ({
    name,
    checked,
    onChange,
    register,
    watch,
    errors,
    extraFields,
    inline,
}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <CheckboxInput
                    name={name}
                    value={name}
                    label={`Use ${name} Rate`}
                    checked={checked}
                    onChange={onChange}
                />

                {checked && (
                    <>
                        <Input
                            type='text'
                            placeholder='Margin (%)'
                            label={`rate_providers.${name}.margin`}
                            register={register}
                            watch={watch}
                            errors={errors}
                            hideErrorMessage
                            size='sm'
                            narrow
                            validate={(value) => !isNaN(Number(value))}
                        />
                        {inline && extraFields}
                    </>
                )}
            </div>
            <div>{checked && !inline && extraFields}</div>
        </div>
    );
};

const CustomRateGrid = ({ register, watch, errors }) => {
    return (
        <table style={{ borderSpacing: '8px' }}>
            <thead style={{ color: 'var(--primary-700)' }}>
                <tr>
                    <th></th>
                    {CUSTOM_RATES_DISTANCES.map(({ id, label }) => (
                        <th key={id}>{label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {CUSTOM_RATES_VEHICLES.map(
                    ({ vehicle_class, display_name }) => (
                        <tr key={vehicle_class}>
                            <th
                                style={{
                                    textAlign: 'right',
                                    color: 'var(--primary-700)',
                                }}
                            >
                                {display_name}
                            </th>
                            {CUSTOM_RATES_DISTANCES.map(
                                ({ id: distance_id }) => (
                                    <td key={`${vehicle_class}.${distance_id}`}>
                                        <Input
                                            type='text'
                                            size='sm'
                                            label={`rate_providers.Custom.base_rates.${vehicle_class}.${distance_id}`}
                                            register={register}
                                            errors={errors}
                                            watch={watch}
                                            placeholder='$/mi'
                                            narrow
                                            hideErrorMessage
                                            validate={(value) =>
                                                !isNaN(Number(value))
                                            }
                                        />
                                    </td>
                                )
                            )}
                        </tr>
                    )
                )}
            </tbody>
        </table>
    );
};

const ContractedLaneRow = ({
    register,
    watch,
    setValue,
    errors,
    index,
    remove,
}) => {
    return (
        <div style={{ display: 'flex', gap: '8px' }}>
            <AutocompleteInput
                id={`rate_providers.Custom.contracted_lanes.${index}.origin`}
                value={
                    watch(
                        `rate_providers.Custom.contracted_lanes.${index}.origin`
                    ) || ''
                }
                setValue={setValue}
                label='Origin'
                register={register}
                errors={errors}
                watch={watch}
                placeholder='Origin'
                size='sm'
                formStyling
                hideErrorMessage
                hideEndIcon
            />

            <AutocompleteInput
                id={`rate_providers.Custom.contracted_lanes.${index}.destination`}
                value={
                    watch(
                        `rate_providers.Custom.contracted_lanes.${index}.destination`
                    ) || ''
                }
                setValue={setValue}
                label='Destination'
                register={register}
                errors={errors}
                watch={watch}
                placeholder='Destination'
                size='sm'
                formStyling
                hideErrorMessage
                hideEndIcon
            />

            <Input
                type='text'
                size='sm'
                label={`rate_providers.Custom.contracted_lanes.${index}.rate`}
                placeholder='Total Rate'
                register={register}
                errors={errors}
                watch={watch}
                startIcon={<FaDollarSign />}
                validate={(value) => !isNaN(Number(value))}
            />

            <Button
                uitype='icon'
                radius='round'
                color='secondary-dark'
                onClick={(event) => {
                    event.preventDefault();
                    remove(index);
                }}
            >
                <HiTrash title='Delete Lane' />
            </Button>
        </div>
    );
};

const CustomRateContractedLanes = ({
    control,
    register,
    watch,
    setValue,
    errors,
}) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'rate_providers.Custom.contracted_lanes',
    });

    return (
        <div>
            <p
                style={{
                    fontWeight: '600',
                    borderBottom: '1px solid black',
                    color: 'var(--secondary-black)',
                    width: 'fit-content',
                    margin: 0,
                }}
            >
                Contracted lanes
            </p>

            <div
                style={{
                    padding: '8px 8px 0 0',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                }}
            >
                {fields.map((item, index) => (
                    <ContractedLaneRow
                        key={item.id}
                        register={register}
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                        index={index}
                        remove={remove}
                    />
                ))}
            </div>

            <Button
                uitype='ghost'
                color='primary-dark'
                size='xs'
                onClick={(event) => {
                    event.preventDefault();
                    append({});
                }}
            >
                <FaPlus /> Add lane
            </Button>
        </div>
    );
};

const AutoQuoteSettingsCard = ({ onSubmit }) => {
    const {
        control,
        register,
        watch,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const providers = [
        {
            name: 'AVRL',
            extraFields: (
                <Input
                    type='text'
                    placeholder='AVRL Shipper Code'
                    label='rate_providers.AVRL.shipper_code'
                    register={register}
                    watch={watch}
                    errors={errors}
                    size='sm'
                    narrow
                    required
                    hideErrorMessage
                />
            ),
            inline: true,
        },
        {
            name: 'Custom',
            extraFields: (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                    }}
                >
                    <CustomRateGrid
                        register={register}
                        watch={watch}
                        errors={errors}
                    />

                    <CustomRateContractedLanes
                        control={control}
                        register={register}
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                    />
                </div>
            ),
        },
    ];

    const [providersOrder, setProvidersOrder] = useState(
        providers.reduce((acc, { name }, index) => {
            acc[name.toLowerCase()] = index;
            return acc;
        }, {})
    );

    const submitHandler = useCallback(
        (values) => {
            const selected_providers = Object.entries(
                values?.rate_providers ?? {}
            )
                .filter(([_, settings]) => settings.selected)
                .map(([provider, settings]) => {
                    delete settings.selected;

                    if (provider === 'Custom') {
                        const base_rates = CUSTOM_RATES_VEHICLES.reduce(
                            (acc_vehicle, { vehicle_class }) => {
                                const vehicle_rates =
                                    CUSTOM_RATES_DISTANCES.reduce(
                                        (acc_distance, distance) => {
                                            if (
                                                settings.base_rates[
                                                    vehicle_class
                                                ]?.[distance.id]
                                            ) {
                                                acc_distance.push({
                                                    ...distance,
                                                    rate: Number(
                                                        settings.base_rates[
                                                            vehicle_class
                                                        ][distance.id]
                                                    ),
                                                });
                                            }
                                            return acc_distance;
                                        },
                                        []
                                    );

                                if (vehicle_rates.length) {
                                    acc_vehicle[vehicle_class] = vehicle_rates;
                                }
                                return acc_vehicle;
                            },
                            {}
                        );

                        const contracted_lanes =
                            settings?.contracted_lanes.filter(
                                (lane) =>
                                    lane.origin && lane.destination && lane.rate
                            );

                        settings.base_rates = base_rates;
                        settings.contracted_lanes = contracted_lanes;
                    }

                    settings.margin = Number(settings.margin);

                    return {
                        rate_provider: provider.toLowerCase(),
                        ...settings,
                    };
                })
                .sort(
                    (a, b) =>
                        providersOrder[a.rate_provider] -
                        providersOrder[b.rate_provider]
                );

            onSubmit({ ...values, rate_providers: selected_providers });
        },
        [onSubmit, providersOrder]
    );

    const changeProviderOrder = useCallback(
        (index, direction) => {
            setProvidersOrder((prev) => {
                const a = Object.entries(prev).find(
                    ([_, order]) => order === index
                )[0];
                const b = Object.entries(prev).find(
                    ([_, order]) => order === index + direction
                )[0];

                const tmp = { ...prev };

                tmp[a.toLowerCase()] = index + direction;
                tmp[b.toLowerCase()] = index;

                return tmp;
            });
        },
        [setProvidersOrder]
    );

    return (
        <div>
            <h2 className={styles.heading}>Auto Quote Rate Setting</h2>
            <form
                className={styles.form}
                onSubmit={handleSubmit(submitHandler)}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                    }}
                >
                    <AutocompleteInput
                        id='default_pickup_location'
                        value={watch('default_pickup_location') || ''}
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        watch={watch}
                        placeholder='Default Pickup Location'
                        size='sm'
                        hideErrorMessage
                        hideEndIcon
                    />

                    <HiOutlineInformationCircle
                        size={24}
                        data-tooltip-content='A fallback when the customer does not specify a location in the email'
                        data-tooltip-id='default-pickup-info-tooltip'
                    />

                    <Tooltip id='default-pickup-info-tooltip' />
                </div>

                {providers
                    .sort(
                        (a, b) =>
                            providersOrder[a.name.toLowerCase()] -
                            providersOrder[b.name.toLowerCase()]
                    )
                    .map(({ name, extraFields, inline }, index) => (
                        <div
                            key={name}
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {index > 0 && (
                                    <FaArrowUp
                                        color='var(--everest-green)'
                                        onClick={() =>
                                            changeProviderOrder(index, -1)
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            marginTop:
                                                index === providers.length - 1
                                                    ? '0.25rem'
                                                    : 0,
                                        }}
                                    />
                                )}
                                {index < providers.length - 1 && (
                                    <FaArrowDown
                                        color='var(--everest-red)'
                                        onClick={() =>
                                            changeProviderOrder(index, 1)
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            marginTop:
                                                index === 0 ? '0.25rem' : 0,
                                        }}
                                    />
                                )}
                            </div>

                            <div
                                style={{ padding: '0.25rem 0 0.25rem 0.5rem' }}
                            >
                                {index}.
                            </div>

                            <RateProviderItem
                                name={name}
                                checked={watch(
                                    `rate_providers.${name}.selected`
                                )}
                                onChange={() =>
                                    setValue(
                                        `rate_providers.${name}.selected`,
                                        !getValues(
                                            `rate_providers.${name}.selected`
                                        )
                                    )
                                }
                                register={register}
                                watch={watch}
                                errors={errors}
                                extraFields={extraFields}
                                inline={inline}
                            />
                        </div>
                    ))}

                <Button uitype='cta' color='primary-dark' size='lg' fullwidth>
                    Save Rates
                </Button>
            </form>
        </div>
    );
};

export { AutoQuoteSettingsCard };
